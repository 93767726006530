.privacy-policy {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #181818;
    margin: 0 auto;
  }
  
  .privacy-title {
    font-size: 3rem;
    color: #111111;
    text-align: center;
    margin-bottom: 20px;
    margin: auto;
    width: 75%;
    margin-top: 100px;
  }
  
  .intro {
    font-size: 1.2rem;
    margin: auto;
    width: 75%;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section h2 {
    font-size: 1.8rem;
    color: #000000;
    margin-bottom: 10px;
    border-bottom: 2px solid #017aaa;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .section p {
    margin: auto;
    width: 75%;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .section ul {
    margin-left: 20px;
    list-style: none; /* Vypnutí teček */
    text-decoration: none;
    padding-left: 10px;
  }
  
  .section ul li {
    margin-bottom: 10px;
  }

  a {
    color: #017aaa;
    text-decoration: none;
    transition: all 0.3s;
  }

  a:hover {
    color: rgb(0, 0, 0);
  }


  @media (max-width: 768px) {
    .privacy-policy {
      font-size: 0.9rem;
    }
  
    .privacy-title {
      font-size: 2rem;
      width: 90%;
      margin-top: 100px;
      margin-bottom: 15px;
    }
  
    .intro {
      font-size: 1rem;
      width: 90%;
      margin-bottom: 15px;
    }
  
    .section h2 {
      font-size: 1.5rem;
      padding-bottom: 4px;
    }
  
    .section p {
      width: 100%;
      font-size: 0.9rem;
    }
  
    .section ul {
      margin-left: 10px;
      padding-left: 5px;
    }
  
    .section ul li {
      font-size: 0.9rem;
    }
  
    a {
      font-size: 0.9rem;
    }
  }
  