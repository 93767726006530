/* General Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.contact-page {
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    color: #202020;
  }
  
  .contact-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 100px;
    color: #000000;
  }

  /* Styl scrollbaru */
::-webkit-scrollbar {
    width: 10px; /* Šířka scrollbaru */
    height: 10px; /* Výška scrollbaru pro horizontální scroll */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #017aaa; /* Barva posuvníku */
    border-radius: 25px !important; /* Zakulacení posuvníku */
    border: 1px solid #202020; /* Oddělení od pozadí */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #000000; /* Barva pozadí tracku */
    border-radius: 25px !important;
  }
  
  /* Styl scrollbaru pro Firefox */
  * {
    scrollbar-width: thin; /* Tenký scrollbar */
    scrollbar-color: #017aaa #000000; /* Barva posuvníku a pozadí */
  }
  
  
  /* Form Styles */
  .form-container {
    margin: auto;
    width: 50%;
    background-color: #f9f9f9;
    padding: 20px 30px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 80%;
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 25px;
    font-size: 0.8rem;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #017aaa;
    outline: none;
  }
  
  .submit-button {
    background: linear-gradient(to right, #ffffff, #e7e7e7);
    color: rgb(0, 0, 0);
    padding: 10px 40px;
    border: none;
    border-radius: 25px;
    font-size: 1.2rem;
    margin-bottom: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgb(0, 0, 0);
  }
  
  .submit-button:hover {
    transform: scale(1.08);
    background: linear-gradient(to right, #000000, #141414);
    color: #e3e3e3;
    border: 1px solid white;
  }
  
  /* Contact Info Section */
  .contact-info {
    margin: auto;
    width: 75%;
    background-color: #020202;
    color: white;
    padding: 20px;
    border-radius: 25px;
    text-align: center;
  }
  
  .contact-info h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contact-info ul {
    list-style: none;
    padding: 0;
  }
  
  .contact-info li {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .contact-info li span {
    margin-right: 10px;
    font-size: 1.5rem;
  }
  
  .contact-info a {
    color: #017aaa;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Footer */
  .footer {
    background-color: #000;
    color: white;
    padding: 20px 0;
    text-align: center;
    margin-top: 40px;
  }
  
  .footer-links a {
    color: #017aaa;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
    color: white;
  }
  

  @media (max-width: 768px) {
  
    .contact-title {
      font-size: 2rem;
      margin-top: 100px;
      margin-bottom: 20px;
    }
  
    .form-container {
      width: 90%;
      padding: 15px;
    }
  
    .contact-form input,
    .contact-form textarea {
      width: 100%;
      font-size: 0.9rem;
      padding: 8px;
    }
  
    .submit-button {
      font-size: 1rem;
      padding: 10px 30px;
    }
  
    .contact-info {
      width: 90%;
      padding: 15px;
    }
  
    .contact-info h2 {
      font-size: 1.5rem;
    }
  
    .contact-info li {
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .footer {
      padding: 15px 10px;
    }
  
    .footer-links a {
      font-size: 0.9rem;
    }
  }
  