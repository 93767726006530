@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  color: #161616;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

/* Global Styles for Header */
.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.966);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.logo-and-name {
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 25px;
  max-width: 50px;
  margin-right: 15px;
}

.name {
  font-size: 0.8rem;
  color: white;
}

.name:hover {
  color: #017aaa;
}

.nav-menu {
  display: flex;
  margin-right: 25px;
  gap: 20px;
  text-decoration: none;
}

.nav-menu a {
  color: white !important;
  text-decoration: none !important;
  font-size: 0.8rem;
  position: relative;
}

.nav-menu a:hover {
  text-decoration: underline;
}

.nav-menu a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #017aaa;
  left: 0;
  bottom: -2px; /* Pozice pod textem */
  transition: width 0.3s ease-in-out;
}

.nav-menu a:hover::after {
  width: 100%;
}


/* Mobile Navigation */
.nav-toggle {
  margin-right: 15px;
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* Scroll Animations */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Banner */
.banner {
  height: 100vh;
  background: url('https://i.ibb.co/HttsTh0/FF6-A1-AED-574-F-469-D-AC34-63-F3-E61-F7233.jpg') center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner-overlay {
  background: rgba(0, 0, 0, 0.384);
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDelayed {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}





.banner-title {
  margin: auto;
  font-size: 4rem;
  line-height: 1.2;
  margin-top: 20%;
  animation: fadeIn 1s ease-out forwards;
  opacity: 0; /* Výchozí stav pro animaci */
}

.banner-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
  animation: fadeIn 1.2s ease-out forwards;
  opacity: 0; /* Výchozí stav pro animaci */
}

.banner-button {
  animation: fadeInDelayed 1.5s ease-out forwards;
  opacity: 0; /* Výchozí stav pro animaci */
  background: linear-gradient(to right, #ffffff, #e7e7e7);
  color: rgb(0, 0, 0);
  padding: 12px 55px;
  border: none;
  border-radius: 25px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.banner-button:hover {
  transform: scale(1.08);
  background: linear-gradient(to right, #ffffff, #ebebeb);
  color: #000000;
  border: 1px solid white;
}

/* Services Section */
.services {
  width: 80%;
  margin: auto;
  padding: 80px 20px;
  background: #e2e2e2;
  text-align: center;
  border-radius: 0px 0px 25px 25px;
}

.services-title {
  font-size: 3rem;
  color: #222;
  margin-bottom: 15px;
}

.services-subtitle {
  font-size: 1.3rem;
  color: #363636;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.service-item {
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Jednotná výška všech karet */
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3.5rem;
  color: #017aaa;
  margin-bottom: 20px;
  margin: auto;
  transition: transform 0.3s ease;
  width: 50px;
  height: auto;
}

.service-item:hover .service-icon {
  transform: rotate(15deg);
}

.service-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #202020;
  margin-bottom: 10px;
}

.service-description {
  font-size: 1rem;
  color: #3a3a3a;
  margin-bottom: 20px;
  flex-grow: 1; /* Rozšíří prostor, pokud je text kratší */
}

.service-price {
  font-size: 2rem;
  font-weight: bold;
  color: #017aaa;
  margin: 15px 0;
  font-weight:bolder;
  min-height: 40px; /* Pevná výška pro zarovnání cen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-button {
  background: #000000;
  color: white;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
  margin-top: auto; /* Zarovnání tlačítka dolů */
  border: none;
}

.service-button:hover {
  transform: scale(1.1);
  background: #e4e4e4;
  border: 1px solid black;
  color: #000000;
}


/* Gallery */
.gallery {
  padding: 80px 20px;
  background: #e3e3e3;
  text-align: center;
}

.gallery-title {
  font-size: 2.8rem;
  color: #222;
  margin-bottom: 30px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Footer */
.footer {
  background: #222;
  color: white;
  padding: 30px 20px;
  text-align: center;
}

.footer-content p {
  font-size: 1rem;
  color: #ccc;
}

.footer-links a {
  color: #017aaa;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {
  body, html {
    font-size: 14px;
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    .nav-menu {
      display: none;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      top: 60px;
      right: 20px;
      background: rgba(0, 0, 0, 0.95);
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    }
  
    .nav-menu.open {
      margin: 0;
      display: flex;
    }
  
    .nav-menu a {
      font-size: 1rem;
      color: white;
      text-align: right;
      padding: 5px 10px;
    }
  
    .nav-toggle {
      display: block;
    }
  }

  .banner-title {
    font-size: 2.5rem;
    margin-top: 100%;
  }

  .banner-subtitle {
    font-size: 1rem;
    padding: 0 10px;
  }

  .banner-button {
    padding: 10px 30px;
    font-size: 0.9rem;
  }

  .services {
    margin: auto;
    width: 80%;
    padding: 40px 10px;
  }

  .services-title {
    font-size: 2rem;
  }

  .services-subtitle {
    font-size: 1.1rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 75px;
  }

  .service-item {
    padding: 15px;
    width: 80%;
    margin: auto;
  }

  .service-icon {
    font-size: 2.5rem;
  }

  .service-title {
    font-size: 1.2rem;
  }

  .service-description {
    font-size: 0.9rem;
  }

  .service-price {
    font-size: 1.5rem;
  }

  .service-button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }

  .gallery {
    padding: 40px 10px;
  }

  .gallery-title {
    font-size: 2rem;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .gallery-image {
    height: 150px;
  }

  .footer {
    padding: 20px 10px;
  }

  .footer-content p {
    font-size: 0.9rem;
  }

  .footer-links a {
    font-size: 0.9rem;
  }
}

