/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

/* Clients Page */
.clients-page {
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  color: #161616;
}

/* Title and Subtitle */
.clients-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
  margin-top: 100px;
}

.clients-subtitle {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 40px;
  color: #1a1a1a;
  font-weight: 300;
}

/* Grid of Images */
.clients-grid {
  margin: auto;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.client-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Lightbox Customization */
.ReactModal__Overlay {
  z-index: 10000;
}

.ReactModal__Content {
  outline: none;
}

/* YouTube Section */
.youtube-title {
  font-size: 1.8rem;
  text-align: center;
  margin: 40px 0 20px;
  color: #222;
}

.youtube-video {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.youtube-video iframe {
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Footer */
.footer {
  background-color: #000;
  color: white;
  padding: 20px 0;
  text-align: center;
  margin-top: 100px;
}

.footer-links a {
  color: #017aaa;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  color: white;
  text-decoration: underline;
}

@media (max-width: 768px) {

  .clients-title {
    font-size: 2rem;
    margin-top: 100px;
  }

  .clients-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .clients-grid {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .client-image {
    border-radius: 15px;
  }

  .youtube-title {
    font-size: 1.5rem;
    margin: 20px 0 10px;
  }

  .youtube-video iframe {
    width: 80%;
    height: 200px;
  }

  .footer {
    padding: 15px 10px;
  }

  .footer-links a {
    font-size: 0.9rem;
  }
}
