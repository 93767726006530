@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.about-page {
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    line-height: 1.8;
  }
  
  .about-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 100px;
    color: #222;
  }
  
  .about-content {
    margin: auto;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 100px;
  }

  /* Levá šipka */
.slick-prev::before {
    color: #017aaa; /* Barva šipky */
    font-size: 2rem; /* Velikost šipky */
    opacity: 1; /* Zajištění viditelnosti */
  }
  
  /* Pravá šipka */
  .slick-next::before {
    color: #017aaa; /* Barva šipky */
    font-size: 2rem; /* Velikost šipky */
    opacity: 1; /* Zajištění viditelnosti */
  }
  
  /* Volitelně: Vylepšení pozice šipek */
  .slick-prev {
    left: -40px; /* Posun šipky vlevo */
    z-index: 10; /* Šipka nad obsahem */
  }
  
  .slick-next {
    right: -40px; /* Posun šipky vpravo */
    z-index: 10; /* Šipka nad obsahem */
  }
  
  
  .about-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
  }
  
  .about-image {
    flex: 1 1 40%; /* Obrázek zabere 40 % šířky */
    max-width: 400px;
    height: auto; /* Zachová poměr stran */
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-text {
    flex: 1 1 55%; /* Text zabere 55 % šířky */
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .carousel {
    margin: 20px 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .carousel-image {
    margin: auto;
    width: 80%;
    height: auto;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .slick-list {
    border-radius: 25px !important;
  }

  
  .gallery {
    margin: auto;
    max-width: 75%;
    margin-top: 100px;
    border-radius: 25px;
    background-color: #e2e2e2;
    color: rgb(0, 0, 0);
    padding: 40px 20px;
  }
  
  .gallery-title {
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .gallery-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .call-to-action {
    font-size: 30px;
    color: #000;
    text-align: center;
    margin: 40px 0;
  }
  
  .cta-button {
    background: linear-gradient(to right, #ffffff, #e7e7e7);
    color: rgb(0, 0, 0);
    padding: 15px 50px;
    border: none;
    border-radius: 25px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgb(0, 0, 0);
  }
  
  .cta-button:hover {
    transform: scale(1.08);
    background: linear-gradient(to right, #000000, #141414);
    color: #e3e3e3;
    border: 1px solid white;
  }
  
  .pricing {
    text-align: center;
    margin: 40px 0;
  }
  
  .pricing-title {
    color: #000;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .pricing-table {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .pricing-row {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .pricing-row:last-child {
    border-bottom: none;
  }
  
  .pricing-amount {
    font-weight: bold;
    color: #017aaa;
  }
  
  .footer {
    max-width: 1920px !important;
    background-color: #000;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-links a {
    color: #00bcd4;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .about-page {
      line-height: 1.6;
    }
  
    .about-title {
      font-size: 2rem;
      margin-top: 100px;
    }
  
    .about-content {
      max-width: 90%;
      gap: 20px;
    }
  
    .about-section {
        flex-direction: column; /* Na mobilu zobrazí prvky pod sebou */
        align-items: center;
      }

      .about-image {
        flex: none; /* Obrázek zabere 100 % šířky */
        max-width: 100%;
      }
  
      .about-text {
        flex: none; /* Text zabere 100 % šířky */
        width: 90%;
        text-align: center;
      }
  
    .carousel {
      max-width: 100%;
    }
  
    .carousel-image {
      width: 100%;
    }
  
    .gallery {
      max-width: 90%;
      padding: 20px;
    }
  
    .gallery-title {
      font-size: 2rem;
      margin-bottom: 30px;
    }
  
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  
    .gallery-image {
      height: 150px;
    }
  
    .call-to-action {
      font-size: 1.5rem;
      margin: 20px 0;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 10px 30px;
    }
  
    .pricing {
      margin: 20px 0;
    }
  
    .pricing-title {
      font-size: 2rem;
    }
  
    .pricing-table {
      padding: 0 10px;
    }
  
    .pricing-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      text-align: left;
    }
  
    .pricing-amount {
      font-size: 1rem;
    }
  
    .footer {
      padding: 15px 10px;
    }
  
    .footer-links a {
      font-size: 0.9rem;
    }
  }
  